import { createApp } from 'vue'
import { createPinia } from 'pinia';
const pinia = createPinia();
import router from './router'
import App from './App.vue'
import i18n from './locales/index'
import 'jquery';
import 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../src/assets/styles/main.less'
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth >= 992) {
    htmlDom.style.fontSize = '16px';
  } else if (htmlWidth >= 768) {
    htmlDom.style.fontSize = '12px';
  } else if (htmlWidth >= 576) {
    htmlDom.style.fontSize = '10px';
  } else if (htmlWidth >= 375) {
    htmlDom.style.fontSize = '8px';
  } else {
    htmlDom.style.fontSize = '4px';
  }
}
window.onresize = setHtmlFontSize
setHtmlFontSize()
const app = createApp(App)
app.use(router).use(i18n).use(pinia).mount('#app')



