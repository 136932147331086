<script setup lang='ts'>
</script>
<template>
    <h1>404 Not Found</h1>
    <hr />
</template>
<style scoped lang="less">
h1 {
    text-align: center;
    padding-top: 20px;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    padding: 0 20px;
}
</style>