<script setup lang='ts'>
import NavbarComponent from './components/NavbarComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const showNavFooter = ref(true);
const router = useRouter();
onMounted(() => {
    router.beforeEach((to, from, next) => {
        if (to.name === 'NotFound') {
            showNavFooter.value = false;
        } else {
            showNavFooter.value = true;
        }
        next();
    });

    // 如果你还希望在离开 404 页面时重新显示导航栏和页脚，可以添加以下逻辑
    router.afterEach((to, from) => {
        if (from.name === 'NotFound') {
            showNavFooter.value = true;
        }
    });
});
</script>
<template>
    <div id="_app">
        <NavbarComponent v-if="showNavFooter" />
        <router-view></router-view>
        <FooterComponent v-if="showNavFooter" />
    </div>
</template>
<style>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
}

#app {
    min-width: 375px;
}

ul {
    margin-bottom: 0 !important;
}
</style>