import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router'
import NotFound from '../views/NotFound.vue'
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: '首页', // 添加标题信息
      description: '首页', // 添加描述信息
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/ProjectsView.vue'),
    meta: {
      title: '产品中心', // 添加标题信息
      description: '产品中心', // 添加描述信息
      keepAlive: true // 标识这个页面需要被缓存
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: '产品中心', // 添加标题信息
      description: '产品中心', // 添加描述信息
      keepAlive: true // 标识这个页面需要被缓存
    }
  },
  {
    //使用正则，
    path: '/:path(.*)',
    name: 'NotFound',
    meta: {
      hide: true
    },
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
