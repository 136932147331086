<template>
    <div class="footer">
        <div class="left">
            <div class="logo">
                <img src="/picture/logo.png" alt="">
                <span class="link">
                    <span class="link-to"><router-link to="/">首页</router-link></span><span class="split"></span>
                    <span class="link-to"><router-link to="/projects">产品展示</router-link></span><span
                        class="split"></span>
                    <span class="link-to"><router-link to="/about">关于我们</router-link></span>
                </span>
            </div>
            <div class="content">杭州市余杭区仓前街道联创街鼎创财富中心B2-13</div>
            <div class="content">官方咨询：0571-56532693</div>
            <div class="content">商务合作：17605811641</div>
            <div class="logo-box">
                <a class="zg" href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017711" target="_blank">
                    <img src="/picture/icon.png" />
                    <span class="margin-right-10">浙公网安备33011002017711</span>
                </a>
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2024086889号-2</a>
            </div>
        </div>
        <div class="qrcode">
            <img src="/picture/qrcode.png" alt="">
            <div>微信公众号</div>
        </div>
    </div>
</template>
<script setup lang="ts">

</script>
<style lang="less" scoped>
.footer {
    width: 100%;
    height: 360px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    color: #000000;

    .logo {
        margin-bottom: 50px;

        img {
            height: 50px;
            margin-right: 80px;
        }

        .link {
            vertical-align: bottom;

            span {
                display: inline-block;
            }

            .link-to {
                vertical-align: bottom;
                margin: 0 20px;
                font-size: 20px;
                cursor: pointer;
            }

            .split {
                width: 2px;
                height: 18px;
                background-color: #707070;
            }

            a {
                color: #000000;
            }

            a:hover,
            .router-link-active {
                text-decoration: none;
                color: #18B7D1;
                // background: linear-gradient(90deg, #08DAC4 0%, #005BFC 100%);
                // background: -webkit-linear-gradient(90deg, #08DAC4 0%, #005BFC 100%);
                // background-clip: text;
                // -webkit-background-clip: text;
                // color: transparent;
                // -webkit-text-fill-color: transparent;
                font-weight: 550;
            }
        }


    }

    .content {
        font-size: 20px;
        color: #000000;
    }

    .qrcode {
        img {
            width: 200px;
            height: 200px;
        }

        div {
            text-align: center;
            font-size: 20px;
        }
    }

    .logo-box {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .zg {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a {
            color: #aaa
        }

        img {
            width: 20px;
            margin-right: 10px;
        }

        .margin-right-10 {
            margin-right: 10px;
        }

    }
}

@media(max-width: 768px) {
    .footer {
        padding: 40px;
        background: #F5F9FD;

        .link {
            display: none;
        }
    }
}
</style>