<template>
    <nav class="navbar navbar-expand-md navbar-light sticky-top" :class="{ 'sticky-blur': isScrolled }" ref="navRef">
        <a class="navbar-brand" href="/">
            <img v-if="isWhite" src='../assets/images/yunchuan-logo-white.png' class="d-inline-block align-top">
            <img v-else src='../assets/images/yunchuan-logo.png' class="d-inline-block align-top">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" :class="{ 'active': activePath === '/', 'active-white': isWhite }"
                    @click="handleNavItemClick('/')">
                    <router-link to="/" class="nav-link" :class="{ 'nav-link-white': isWhite }">{{ t('home')
                        }}</router-link>
                </li>
                <li class="nav-item " :class="{ 'active': activePath === '/projects', 'active-white': isWhite }"
                    @click="handleNavItemClick('/projects')">
                    <router-link to="/projects" class="nav-link" :class="{ 'nav-link-white': isWhite }">{{ t('projects')
                        }}</router-link>
                </li>
                <li class="nav-item" :class="{ 'active': activePath === '/about', 'active-white': isWhite }"
                    @click="handleNavItemClick('/about')">
                    <router-link to="/about" class="nav-link" :class="{ 'nav-link-white': isWhite }">{{ t('about')
                        }}</router-link>
                </li>
            </ul>
            <!-- <div>中/EN</div> -->
        </div>
    </nav>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router'
import $ from 'jquery'
const { t } = useI18n();
const isScrolled = ref(false)
const route = useRoute()
const activePath = ref('/');
import { useHeightStore } from '@/stores/index'
const store = useHeightStore();
const navRef = ref<HTMLElement | null>(null);
const isWhite = ref(false);
let resizeObserver: ResizeObserver | null = null;
onMounted(() => {
    const navbarCollapse = document.getElementById('navbarSupportedContent');
    if (navbarCollapse) {
        $('#navbarSupportedContent').on('show.bs.collapse', handleShow)
        $('#navbarSupportedContent').on('hide.bs.collapse', handleHide);
    }
    window.addEventListener('scroll', handleScroll);
    resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            const offsetHeight = (entry.target as HTMLElement).offsetHeight; // 使用 ! 来进行非空断言
            store.height = offsetHeight;
        }
    });
    if (navRef.value) {
        resizeObserver.observe(navRef.value);
    }

});
const handleHide = () => {
    activePath.value === '/' ? isWhite.value = true : isWhite.value = false;
    if (navRef.value) {
        navRef.value.style.backgroundColor = 'rgba(255, 255, 255,0.4)';
    }
};
const handleShow = () => {
    isWhite.value = false
    if (navRef.value) {
        navRef.value.style.backgroundColor = 'rgb(255, 255, 255)';
    }
};
watch(
    () => route.path,
    (newParams) => {
        // 当路由参数变化时，更新 params 的值
        scrollTo(0, 0);
        route.path = newParams
        activePath.value = newParams
        if (navRef.value) {
            navRef.value.style.backgroundColor = 'rgba(255, 255, 255,0.4)'
        }
        if (newParams !== '/') {
            isScrolled.value = true
            isWhite.value = false
        } else {
            isScrolled.value = false
            isWhite.value = true
        }
    },
    // 可选的选项，比如立即执行回调、深度比较等
    { immediate: false, deep: true } // 注意：对于对象，你可能需要深度比较，但路由参数通常是一个简单的对象
)
const handleNavItemClick = (path: string) => {
    activePath.value = path;
    const navbarCollapse = document.getElementById('navbarSupportedContent');
    if (navbarCollapse?.classList.contains('show')) {
        // 触发 Bootstrap 的折叠功能
        navbarCollapse.classList.remove('show');
    }
}
onUnmounted(() => {
    if (resizeObserver) {
        resizeObserver.disconnect();
    }
});
const handleScroll = () => {
    if (window.scrollY > 50) {
        isScrolled.value = true;
        isWhite.value = false
    } else {
        if (activePath.value === '/') {
            isScrolled.value = false;
            isWhite.value = true
        }
    }
};
// const SwitchLanguage = (lang: string) => {
//     console.log(lang);
//     locale.value = lang;
//     localStorage.setItem('LANG', lang);
// }
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>
<style scoped lang="less">
.navbar {
    .navbar-nav {
        .active-white {
            &::before {
                background: #fff;
            }
        }
    }
}

.navbar-nav {
    width: 100%;
    justify-content: center;

    .active {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 30px;
            height: 2px;
            background: #000;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .nav-item {
        margin-left: 20px;

        &:first-of-type {
            margin-left: -10%;
        }
    }
}

.navbar-brand {
    margin-left: 40px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .navbar-nav {
        background: #fff;
        font-size: 20px;

        .nav-item {
            .nav-link {
                color: #848884 !important;
            }
        }

        .active {
            .nav-link {
                color: #000 !important;
            }

            &::before {
                display: none;
            }
        }

        .nav-item {
            &:first-of-type {
                margin-left: 20px;
            }
        }
    }

    .navbar-toggler-icon {
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 576px) {
    .navbar-nav {
        font-size: 30px;
        background: #fff;

        .nav-item {
            .nav-link {
                color: #848484 !important;
            }
        }

        .active {
            .nav-link {
                color: #000 !important;
            }

            &::before {
                display: none;
            }
        }

        .nav-item {
            &:first-of-type {
                margin-left: 20px;
            }
        }
    }

    .navbar-toggler-icon {
        width: 40px;
        height: 40px;
    }
}

.align-top {
    width: 100px;
    height: auto;
}

.nav-item {
    .active {
        &::before {
            background: #fff;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .nav-link-white {
        color: #fff !important;
    }
}

.nav-link {
    color: #000 !important;
    font-weight: 700 !important;
}

.sticky-blur {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.navbar-light .navbar-toggler {
    border: none;
}

.navbar {
    height: 100%;
    padding: 20px 0;
}

.navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        padding: 0 5px;
        white-space: nowrap;
        margin-bottom: 0;
        background: linear-gradient(to right, rgba(65, 175, 164), #2e7be5, rgba(65, 175, 164), #2e7be5, rgba(65, 175, 164), #2e7be5, rgba(65, 175, 164));
        color: transparent;
        font-size: 22px;
        font-weight: 700;
        font-style: italic;
        /* 将字体设置为斜体 */
    }

    .dropdown {
        .dropdown-item {
            font-style: italic;
        }

        .icon-search {
            width: 33px;
        }
    }
}
</style>